import { IAvailableFilters, IVentetid } from '../types/ExtendEntities';
import { ColumnNames, HelseRegion } from '../types/ExtendEnums';
import { Ventetid, VentetiderForBehandlingBehandlingssted } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { ActiveFilter } from '@helsenorge/filter/components/filter/useFilters';

import { TabellData } from './behandlingssted-expanded';
import { FilterObject, FilterValue } from '../behandlingssteder/filters-helper';
import { sortFilterValues } from '../utils/filters';
import { hentVentetidString, hentHelseregionString } from '../utils/strings';

function convertFirstCharacterToUpperCase(text: string): string {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
}

export const getFilteredBehandlingssteder = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<VentetiderForBehandlingBehandlingssted>,
  activeFilters?: Array<ActiveFilter>
): Array<VentetiderForBehandlingBehandlingssted> => {
  const helseregionCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterHelseregion).map(filter => filter.value) ?? [];
  const fylkeCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterFylke).map(filter => filter.value) ?? [];
  const tilbudCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterTilbud).map(filter => filter.value) ?? [];
  const filterRettighetsvurdering = activeFilters?.some(filter => filter.value === resources.filterRettighetvurdering);

  return helseregionCheckedFilters.length || fylkeCheckedFilters.length || tilbudCheckedFilters
    ? behandlingssteder
        .filter((behandlingssted: VentetiderForBehandlingBehandlingssted) => {
          let helseregionMatch = helseregionCheckedFilters.length ? false : true;
          if (helseregionCheckedFilters.length && !!behandlingssted.helseRegioner && behandlingssted.helseRegioner.length) {
            for (const helseRegion of behandlingssted.helseRegioner) {
              if (helseregionCheckedFilters.includes(helseRegion)) {
                helseregionMatch = true;
                break;
              }
            }
          }

          let fylkeMatch = true;
          if (fylkeCheckedFilters.length) {
            fylkeMatch = behandlingssted.fylke ? fylkeCheckedFilters.includes(behandlingssted.fylke.toLowerCase()) : false;
          }

          let tilbudMatch = true;
          if (tilbudCheckedFilters.length) {
            tilbudMatch = !!behandlingssted.ventetider?.find(x => x.type && tilbudCheckedFilters.includes(x.type.toLowerCase()));
          }

          let rettighetvurderingMatch = true;
          if (filterRettighetsvurdering) {
            rettighetvurderingMatch = behandlingssted.harVurderingskompetanse;
          }

          return helseregionMatch && fylkeMatch && tilbudMatch && rettighetvurderingMatch;
        })
        .map(behandlingssted => {
          let ventetider: Ventetid[] | null = behandlingssted.ventetider;
          if (tilbudCheckedFilters.length > 0) {
            ventetider =
              behandlingssted.ventetider?.map((ventetid: IVentetid) => {
                const v = { ...ventetid };
                if (ventetid.type && tilbudCheckedFilters.includes(ventetid.type)) {
                  v.skalVisesIVentetidCell = true;
                } else {
                  v.skalVisesIVentetidCell = false;
                }
                return v;
              }) || [];
          }
          return { ...behandlingssted, ventetider };
        })
    : behandlingssteder;
};

export const hentAntallBehandlingerString = (resources: HNVelgBehandlingsstedFrontend, antallBehandlinger: number | null): string => {
  if (!antallBehandlinger) {
    return '-';
  } else if (antallBehandlinger === 1) {
    return `${antallBehandlinger} ${resources.tabellAntallBehandling}`;
  }
  return `${antallBehandlinger} ${resources.tabellAntallBehandlinger}`;
};

export const getFiltersAvailableFromDataSet = (data: TabellData[], resources: HNVelgBehandlingsstedFrontend): IAvailableFilters => {
  let region: string[] = [];
  let tilgjengeligTilbud: string[] = [];
  let fylker: string[] = [];

  data.forEach(behandlingssted => {
    const regioner = behandlingssted.behandlingsstedData.helseRegioner as string[];
    const fylke = behandlingssted.behandlingsstedData.fylke;
    const tilbud = behandlingssted.behandlingsstedData.ventetider
      ?.map(x => x.type)
      ?.filter(x => {
        return x !== null;
      }) as string[];

    region = [...region, ...regioner];

    if (behandlingssted.behandlingsstedData.harVurderingskompetanse) {
      tilgjengeligTilbud.push(resources.filterRettighetvurdering);
    }

    if (!!tilbud && tilbud.length > 0) {
      tilgjengeligTilbud = [...tilgjengeligTilbud, ...tilbud];
    }

    if (fylke) {
      fylker = [...fylker, fylke.toLowerCase()];
    }
  });

  return { regioner: [...new Set(region)], fylker: [...new Set(fylker)], tilbud: [...new Set(tilgjengeligTilbud)] };
};

export const skalVisesIVentetidCell = (x: IVentetid): boolean =>
  x.skalVisesIVentetidCell || x.skalVisesIVentetidCell === null || x.skalVisesIVentetidCell === undefined;

export const getTabellData = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<VentetiderForBehandlingBehandlingssted>,
  activeFilters: Array<ActiveFilter> | undefined = undefined
): Array<TabellData> => {
  return getFilteredBehandlingssteder(resources, behandlingssteder, activeFilters).map(
    (behandlingssted: VentetiderForBehandlingBehandlingssted) => {
      return {
        behandlingsstedData: behandlingssted,
        [ColumnNames.Behandlingssted]: behandlingssted.navn ? behandlingssted.navn : '',
        [ColumnNames.Ventetid]: hentVentetidString(resources, behandlingssted.ventetider?.filter(skalVisesIVentetidCell)),
        [ColumnNames.Antall]: hentAntallBehandlingerString(resources, behandlingssted.antall),
      };
    }
  );
};

export const getInitialFilters = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<VentetiderForBehandlingBehandlingssted>
): Array<FilterObject> => {
  let helseregionFilterValues: FilterValue[] = [];
  let fylkeFilterValues: FilterValue[] = [];
  let tilbudFilterValues: FilterValue[] = [
    {
      checked: false,
      id: resources.filterRettighetvurdering,
      displayName: convertFirstCharacterToUpperCase(resources.filterRettighetvurdering),
      value: resources.filterRettighetvurdering,
    },
  ];

  behandlingssteder.forEach((behandlingssted: VentetiderForBehandlingBehandlingssted, index: number) => {
    if (!!behandlingssted.helseRegioner && behandlingssted.helseRegioner.length) {
      for (const helseRegion of behandlingssted.helseRegioner) {
        if (
          !helseregionFilterValues.some(
            filterValue => helseRegion !== HelseRegion.Unknown && !!helseRegion && filterValue.value === helseRegion
          )
        ) {
          helseregionFilterValues = helseregionFilterValues.concat({
            checked: false,
            id: `helseregion-${helseRegion}`,
            displayName: hentHelseregionString(helseRegion, resources),
            value: helseRegion ? helseRegion : '',
          });
        }
      }
    }

    if (behandlingssted.fylke) {
      const fylkeFilter = fylkeFilterValues.find(
        filterValue => !!filterValue.value && !!behandlingssted.fylke && filterValue.value === behandlingssted.fylke.toLowerCase()
      );

      if (!fylkeFilter) {
        fylkeFilterValues = fylkeFilterValues.concat({
          checked: false,
          id: `fylke${index}`,
          displayName: behandlingssted.fylke,
          value: behandlingssted.fylke.toLowerCase(),
        });
      }
    }

    if (behandlingssted.ventetider && behandlingssted.ventetider?.length > 0) {
      const tilbud = behandlingssted.ventetider.map(x => x.type?.toLowerCase() || '');

      const currentTilbudFilter = tilbudFilterValues.map(x => x.value);
      const filterToAdd = tilbud.filter(x => !currentTilbudFilter.includes(x) && x !== '');

      if (filterToAdd.length > 0) {
        const nyeTilbudFiltre = filterToAdd.map(
          x =>
            ({
              checked: false,
              id: x,
              displayName: convertFirstCharacterToUpperCase(x),
              value: x.toLowerCase(),
            }) as FilterValue
        );

        tilbudFilterValues = [...tilbudFilterValues, ...nyeTilbudFiltre];
      }
    }
  });

  return [
    {
      index: 0,
      values: sortFilterValues(helseregionFilterValues),
      name: resources.filterHelseregion,
    },
    {
      index: 1,
      values: sortFilterValues(fylkeFilterValues),
      name: resources.filterFylke,
    },
    {
      index: 2,
      values: tilbudFilterValues,
      name: resources.filterTilbud,
    },
  ];
};

const onlyOneFilterIsInUse = (
  filters: FilterObject[],
  resources: HNVelgBehandlingsstedFrontend,
  filterType: 'fylke' | 'region'
): boolean => {
  let fylkeChecked = false;
  let regionChecked = false;

  for (const filter of filters) {
    if (filter.name === resources.filterFylke) {
      fylkeChecked = !!filter.values?.some(value => {
        return value.checked;
      });
    }
    if (filter.name === resources.filterHelseregion) {
      regionChecked = !!filter.values?.some(value => {
        return value.checked;
      });
    }
  }
  const kunFylke = filterType === 'fylke' && fylkeChecked && !regionChecked;
  const kunRegion = filterType === 'region' && !fylkeChecked && regionChecked;
  return kunFylke || kunRegion;
};

export const findFilterObject = (
  filterObjects: FilterObject[],
  resources: HNVelgBehandlingsstedFrontend,
  filterType: 'fylke' | 'region'
): FilterObject | undefined => {
  for (const filterObject of filterObjects) {
    if (filterType === 'fylke' && filterObject.name === resources.filterFylke) {
      return filterObject;
    }
    if (filterType === 'region' && filterObject.name === resources.filterHelseregion) {
      return filterObject;
    }
  }
  return undefined;
};

export const getAvailableFilters = (
  availableFilters: IAvailableFilters,
  filters: FilterObject[],
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<VentetiderForBehandlingBehandlingssted>
): FilterObject[] => {
  return filters.map(filter => {
    if (filter.name === resources.filterFylke) {
      const originalFilterObeject = findFilterObject(getInitialFilters(resources, behandlingssteder), resources, 'fylke');
      if (onlyOneFilterIsInUse(filters, resources, 'fylke') && originalFilterObeject) {
        filter.values = originalFilterObeject.values?.map(x => {
          return { ...x, disable: false, checked: filter.values?.find(e => e.displayName === x.displayName)?.checked };
        });
        return filter;
      }
      filter.values = filter.values?.map(x => {
        const isDisabled = !!x.value && !availableFilters.fylker.includes(x.value as string);
        return { ...x, disable: isDisabled };
      });
      return filter;
    }
    if (filter.name === resources.filterHelseregion) {
      const originalFilterObeject = findFilterObject(getInitialFilters(resources, behandlingssteder), resources, 'region');
      if (onlyOneFilterIsInUse(filters, resources, 'region') && originalFilterObeject) {
        filter.values = originalFilterObeject.values?.map(x => {
          return { ...x, disable: false, checked: filter.values?.find(e => e.displayName === x.displayName)?.checked };
        });
        return filter;
      }
      filter.values = filter.values?.map(x => {
        const isDisabled = !!x.value && !availableFilters.regioner.includes(x.value as string);
        return { ...x, disable: isDisabled };
      });
      return filter;
    }
    if (filter.name === resources.filterTilbud) {
      filter.values = filter.values?.map(x => {
        const isDisabled = !!x.value && !availableFilters.tilbud.includes(x.value as string);
        return { ...x, disable: isDisabled };
      });
    }
    return filter;
  });
};

export const getTableCaption = (resources: HNVelgBehandlingsstedFrontend, navn: string): string => {
  return `${resources.ventetiderForBehandlingTableCaption} ${navn}${resources.ventetiderForBehandlingTableCaption2}`;
};
