import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import NotFound from '@helsenorge/core-framework/page-not-found';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getPath, getAssetsUrl } from '@helsenorge/framework-utils/hn-page';
import { fetchResources, getResourcesFromState } from '@helsenorge/framework-utils/resources';
import { configureStoreWithMiddleware } from '@helsenorge/framework-utils/store';
import WebCompConsumer from '@helsenorge/framework-utils/web-component/consumer';

import Behandlinger from './behandlinger';
import Behandlingssteder from './behandlingssteder';
import Forside from './forside';
import { getResources } from './resources';
import rootReducer, { GlobalState, GlobalAction } from './root';
import PageContainer from './shared/page-container';
import { Urls } from './types/ExtendEnums';
import { HNVelgBehandlingsstedFrontend } from './types/Resources';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from './utils/resources-project';
import VentetiderForBehandling from './ventetider-for-behandling';

import './styles.scss';

const vbsBreadCrumbResourceId = 'page_VelgBehandlingssted_NavigateBackLinkTitle';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <PageContainer pageClass="vbs-forside" hasFullwidth>
          <Forside />
        </PageContainer>
      ),
    },

    {
      path: Urls.Behandlinger,
      element: (
        <PageContainer
          pageClass="vbs-behandlinger"
          breadcrumbLink={{ resourceId: vbsBreadCrumbResourceId, url: '/', renderAsRouterLink: true }}
        >
          <Behandlinger />
        </PageContainer>
      ),
    },
    {
      path: Urls.VentetiderForBehandling,
      element: (
        <PageContainer
          pageClass="vbs-ventetider-for-behandling"
          breadcrumbLink={{ resourceId: 'page_Behandlinger_NavigateBackLinkTitle', url: Urls.Behandlinger, renderAsRouterLink: true }}
        >
          <VentetiderForBehandling />
        </PageContainer>
      ),
    },
    {
      path: Urls.Behandlingssteder,
      element: (
        <PageContainer
          pageClass="vbs-behandlingssteder"
          breadcrumbLink={{ resourceId: vbsBreadCrumbResourceId, url: '/', renderAsRouterLink: true }}
        >
          <Behandlingssteder />
        </PageContainer>
      ),
    },
    {
      path: Urls.Behandlingssteder,
      element: (
        <PageContainer
          pageClass="vbs-behandlingssteder"
          breadcrumbLink={{ resourceId: vbsBreadCrumbResourceId, url: '/', renderAsRouterLink: true }}
        >
          <Behandlingssteder />
        </PageContainer>
      ),
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
  { basename: '/' + getPath() }
);

const VelgBehandlingsstedRoot: React.FunctionComponent = () => {
  const dispatch: ThunkDispatch<GlobalState, void, GlobalAction> = useDispatch();
  const state = useSelector((state: GlobalState) => state);
  const resources = getResourcesFromState(
    state,
    HN_VELG_BEHANDLINGSSTED_FRONTEND,
    LanguageLocales.NORWEGIAN
  ) as HNVelgBehandlingsstedFrontend;

  React.useEffect(() => {
    dispatch(fetchResources(HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN, getResources));
  }, []);

  return resources && <RouterProvider router={router} />;
};

const store = configureStoreWithMiddleware(rootReducer);

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('main-content-wrapper')!).render(
    <React.StrictMode>
      <React.Fragment>
        <template id="hn-webcomp-cms-block-promopanel-template"></template>

        <Provider store={store}>
          <VelgBehandlingsstedRoot />
        </Provider>

        <WebCompConsumer
          domain={`${getAssetsUrl()}/cms-blocks`}
          entryName="src/index.tsx"
          componentName="hn-webcomp-cms-block-promopanel"
          componentProps={{ externalid: 'velg-behandlingssted' }}
          includeHelsenorgeCss
        />
      </React.Fragment>
    </React.StrictMode>
  );
});
